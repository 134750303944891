import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="p-6 max-w-5xl mx-auto bg-white rounded-lg">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Privacy Policy</h1>
      <p className="text-sm text-gray-600 mb-4">
        <strong>Effective Date:</strong> 26.11.2024
      </p>
      <p className="text-gray-700 mb-6">
        <b>eOrar ASEM</b> is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share information when you use our service. By using our app, you agree to the terms outlined in this policy.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">1. Information We Collect</h2>

      <h3 className="text-xl font-medium mb-3 text-gray-800">1.1 Personal Information</h3>
      <p className="text-gray-700 mb-4">
        When you authenticate using your Google account, we collect the following:
      </p>
      <ul className="list-disc list-inside mb-6 text-gray-700 space-y-2">
        <li>
          <strong>Google Account Information:</strong> Your email address and
          basic profile information (e.g., name, profile picture).
        </li>
        <li>
          <strong>Google Calendar Data (Optional):</strong> If you grant
          permission, we access your calendar to add events you choose to
          schedule.
        </li>
      </ul>

      <h3 className="text-xl font-medium mb-3 text-gray-800">1.2 Usage Data</h3>
      <p className="text-gray-700 mb-4">
        We may collect non-identifiable information about how you interact with
        the app, such as:
      </p>
      <ul className="list-disc list-inside mb-6 text-gray-700 space-y-2">
        <li>Device type (e.g., mobile or desktop)</li>
        <li>Browser type</li>
        <li>Usage patterns and preferences</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        2. How We Use Your Information
      </h2>
      <ul className="list-disc list-inside mb-6 text-gray-700 space-y-2">
        <li>
          <strong>Google Calendar Integration:</strong> To add events to your
          Google Calendar when explicitly requested by you.
        </li>
        <li>
          <strong>Service Improvement:</strong> To analyze app usage and
          improve functionality.
        </li>
        <li>
          <strong>Communication:</strong> To respond to your inquiries or
          feedback.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        3. Information Sharing
      </h2>
      <p className="text-gray-700 mb-4">
        We do not share your personal information with third parties except in
        the following situations:
      </p>
      <ul className="list-disc list-inside mb-6 text-gray-700 space-y-2">
        <li>
          <strong>With Your Consent:</strong> When you explicitly allow us to
          share data for a specific purpose (e.g., adding an event to your
          Google Calendar).
        </li>
        <li>
          <strong>Legal Obligations:</strong> When required by law to comply
          with legal obligations, such as responding to lawful requests by
          public authorities.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        4. Data Retention
      </h2>
      <p className="text-gray-700 mb-6">
        We retain your personal data only for as long as necessary to provide
        the services you use or comply with legal obligations. If you disconnect
        your Google account, we will no longer have access to your data.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        5. How We Protect Your Information
      </h2>
      <p className="text-gray-700 mb-4">
        We implement industry-standard security measures to protect your data,
        including:
      </p>
      <ul className="list-disc list-inside mb-6 text-gray-700 space-y-2">
        <li>Encryption of sensitive data during transmission.</li>
        <li>Secure storage of any retained data.</li>
        <li>Regular audits of our security practices.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">6. Your Rights</h2>
      <p className="text-gray-700 mb-4">
        As a user, you have the following rights regarding your personal data:
      </p>
      <ul className="list-disc list-inside mb-6 text-gray-700 space-y-2">
        <li>
          <strong>Access and Portability:</strong> You can request a copy of the
          data we collect.
        </li>
        <li>
          <strong>Revocation of Consent:</strong> You can revoke Google Calendar
          permissions at any time via your Google account settings.
        </li>
        <li>
          <strong>Deletion:</strong> You can request the deletion of your data
          by contacting us.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        7. Third-Party Services
      </h2>
      <p className="text-gray-700 mb-6">
        eOrar ASEM integrates with the following third-party service:
      </p>
      <ul className="list-disc list-inside mb-6 text-gray-700 space-y-2">
        <li>
          <strong>Google Calendar API:</strong> Used to add events to your
          Google Calendar. This service is governed by Google’s{' '}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            Privacy Policy
          </a>
          .
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        8. Children’s Privacy
      </h2>
      <p className="text-gray-700 mb-6">
        Our service is not intended for children under the age of 13. We do not
        knowingly collect personal data from children. If we become aware that
        we have collected data from a child under 13, we will take immediate
        steps to delete it.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        9. Changes to This Privacy Policy
      </h2>
      <p className="text-gray-700 mb-6">
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or for other operational, legal, or regulatory reasons.
        We encourage you to review this page periodically for the latest
        information.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">10. Contact Us</h2>
      <p className="text-gray-700 mb-6">
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us:
      </p>
      <ul className="list-disc list-inside mb-6 text-gray-700 space-y-2">
        <li>
          <strong>Email:</strong> code.antonyo@gmail.com
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        11. Acknowledgment
      </h2>
      <p className="text-gray-700">
        By using eOrar ASEM, you acknowledge that you have read and understood
        this Privacy Policy and agree to its terms.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
