import React, { useEffect } from 'react';
import './../App.css';

const SnowEffect = () => {
  useEffect(() => {
    // Creăm un container pentru fulgii de zăpadă
    const snowContainer = document.createElement('div');
    snowContainer.className = 'snow-container';
    document.body.appendChild(snowContainer);

    let snowInterval;
    const isMobile = window.innerWidth <= 768;
    const MAX_SNOWFLAKES = isMobile ? 10 : 30; // 10 pe mobil, 30 pe desktop
    let currentSnowflakes = 0; // Numărul curent de fulgi pe ecran

    // Variabilă pentru înclinare
    let tiltX = 0;

    // Funcție pentru a crea și anima fulgii de zăpadă
    const createSnowflake = () => {
      if (currentSnowflakes >= MAX_SNOWFLAKES) {
        return; // Nu creăm mai mulți fulgi decât limita maximă
      }

      const snowflake = document.createElement('div');
      snowflake.className = 'snowflake';
      snowflake.style.left = `${Math.random() * 100}vw`;

      // Ajustăm durata animației în funcție de dispozitiv
      const animationDurationBase = isMobile ? 10 : 3; // Mai lent pe mobil
      const animationDurationRange = isMobile ? 5 : 5;
      snowflake.style.animationDuration = `${
        animationDurationBase + Math.random() * animationDurationRange
      }s`;

      // Ajustăm opacitatea și dimensiunea fulgilor
      snowflake.style.opacity = `${0.3 + Math.random() * 0.3}`; // Opacitate între 0.3 și 0.6
      const fontSizeBase = isMobile ? 8 : 10;
      snowflake.style.fontSize = `${fontSizeBase + Math.random() * 10}px`;
      snowflake.innerHTML = '❄';

      // Adăugăm efectul de înclinare
      snowflake.style.setProperty('--tilt', `${tiltX * 0.1}deg`);

      snowContainer.appendChild(snowflake);

      currentSnowflakes++;

      // Animăm topirea fulgului aproape de bază
      snowflake.addEventListener(
        'animationend',
        () => {
          snowflake.style.animation = 'melt 1s linear forwards'; // Animație de topire

          snowflake.addEventListener(
            'animationend',
            () => {
              snowflake.remove(); // Înlăturăm fulgul după topire
              currentSnowflakes--;
            },
            { once: true }
          );
        },
        { once: true }
      );
    };

    // Începem generarea de fulgi
    const startSnowfall = () => {
      if (!snowInterval) {
        const intervalTime = isMobile ? 4000 : 1000; // 4s pe mobil, 1s pe desktop
        snowInterval = setInterval(createSnowflake, intervalTime); // Frecvența de generare
      }
    };

    // Oprim generarea de fulgi
    const stopSnowfall = () => {
      if (snowInterval) {
        clearInterval(snowInterval);
        snowInterval = null;
      }
    };

    // Gestionăm schimbarea de vizibilitate a tab-ului
    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopSnowfall(); // Pauză la fulgi când tab-ul nu este activ
      } else {
        startSnowfall(); // Reluăm generarea de fulgi când tab-ul devine activ
      }
    };

    // Gestionăm înclinarea dispozitivului
    const handleDeviceOrientation = (event) => {
      tiltX = event.gamma; // Obținem înclinarea pe axa X
      // Actualizăm proprietatea CSS pentru toți fulgii existenți
      const flakes = document.querySelectorAll('.snowflake');
      flakes.forEach((flake) => {
        flake.style.setProperty('--tilt', `${tiltX * 0.1}deg`);
      });
    };

    // Gestionăm mișcarea de "shake"
    let lastShakeTime = 0;
    const handleDeviceMotion = (event) => {
      const acceleration = event.accelerationIncludingGravity;
      const currentTime = Date.now();

      // Calculăm magnitudinea mișcării
      const magnitude = Math.sqrt(
        acceleration.x * acceleration.x +
          acceleration.y * acceleration.y +
          acceleration.z * acceleration.z
      );

      if (magnitude > 25 && currentTime - lastShakeTime > 1000) {
        // Detectăm un "shake" dacă magnitudinea depășește un prag
        lastShakeTime = currentTime;
        spawnExtraSnowflakes();
      }
    };

    // Funcție pentru a genera fulgi suplimentari
    const spawnExtraSnowflakes = () => {
      const extraFlakes = 30; // Numărul de fulgi suplimentari
      for (let i = 0; i < extraFlakes; i++) {
        createSnowflake();
      }
      // Curățăm fulgii suplimentari după 5 secunde
      setTimeout(() => {
        const flakes = document.querySelectorAll('.snowflake');
        flakes.forEach((flake) => {
          flake.remove();
          currentSnowflakes--;
        });
      }, 5000);
    };

    // Atașăm listenerii
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('deviceorientation', handleDeviceOrientation);
    window.addEventListener('devicemotion', handleDeviceMotion);

    // Începem generarea de fulgi inițial
    startSnowfall();

    // Curățăm la demontarea componentei
    return () => {
      stopSnowfall();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('deviceorientation', handleDeviceOrientation);
      window.removeEventListener('devicemotion', handleDeviceMotion);
      snowContainer.remove();
    };
  }, []);

  return null;
};

export default SnowEffect;
