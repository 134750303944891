import React from 'react';
import './../../App.css';

const Modal = ({ isVisible, title, message, onConfirm, onCancel, singleButtonLabel }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[999]">
      <div className="modal-container bg-white rounded-lg shadow-lg p-6 w-96">
        <h2 className="text-xl font-semibold mb-4">{title}</h2>
        <p className="mb-6">{message}</p>
        <div className="flex justify-end space-x-4">
          {singleButtonLabel ? (
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={onConfirm}
            >
              {singleButtonLabel}
            </button>
          ) : (
            <>
              <button
                className="px-4 py-2 bg-gray-500 rounded hover:bg-gray-400"
                onClick={onCancel}
              >
                Anulează
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={onConfirm}
              >
                Confirmă
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
