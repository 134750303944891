// SearchBar.js

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Select from 'react-select';
import PropTypes from 'prop-types';
import './css/SearchBar.css';
import CurrentWeek from './CurrentWeek';
import { debounce } from './utils/debounce';
import Modal from 'react-modal';
import { QRCode } from 'react-qrcode-logo';

Modal.setAppElement('#root');

const SearchBar = ({ onSearch, onToggleTheme, isDarkMode, isAdmin, selectedFilters }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const currentWeekType = CurrentWeek();
  const [loading, setLoading] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedProfessor, setSelectedProfessor] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const [options, setOptions] = useState({ groups: [] });
  const [filteredOptions, setFilteredOptions] = useState({ professors: [], rooms: [] });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const generateShareLink = () => {
    const baseUrl = window.location.origin;
    const params = new URLSearchParams();
    if (selectedGroup) params.append('group', selectedGroup.value);
    if (selectedProfessor) params.append('professor', selectedProfessor.value);
    if (selectedRoom) params.append('room', selectedRoom.value);
    return `${baseUrl}/?${params.toString()}`;
  };

  const shareLink = generateShareLink();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink);
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2000);
  };

  const handleShareClick = () => {
    setIsModalOpen(true);
    setIsClosing(false);
  };
  
  const handleCloseModal = () => {
    setIsClosing(true);
  
    // Wait for the animation to complete before closing the modal
    setTimeout(() => {
      setIsModalOpen(false);
      setIsClosing(false);
    }, 300); // Match the duration of your CSS animation (0.3s)
  };


  const fetchOptions = useCallback(
    debounce(async (groupName = null) => {
      setLoading(true);
      try {
        if (!options.groups.length) {
          const groupResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/groups/options`);
          const groupOptions = groupResponse.data.groups
            .map(group => ({ value: group.name, label: group.name }))
            .sort((a, b) => a.label.localeCompare(b.label));

          setOptions(prevOptions => ({
            ...prevOptions,
            groups: groupOptions,
          }));
        }

        if (groupName) {
          const groupResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/groups/${groupName}`);
          const schedule = groupResponse.data.schedule;

          const professors = [...new Set(schedule.map(item => item.professor).filter(Boolean))];
          const rooms = [...new Set(schedule.map(item => item.room).filter(Boolean))];

          const professorOptions = professors
            .map(prof => ({ value: prof, label: prof }))
            .sort((a, b) => a.label.localeCompare(b.label));
          const roomOptions = rooms
            .map(room => ({ value: room, label: room }))
            .sort((a, b) => a.label.localeCompare(b.label));

          setFilteredOptions({
            professors: professorOptions,
            rooms: roomOptions,
          });
        } else {
          const [professorResponse, roomResponse] = await Promise.all([
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/professors`),
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/rooms`),
          ]);

          const professorOptions = professorResponse.data
            .map(prof => ({ value: prof.name, label: prof.name }))
            .sort((a, b) => a.label.localeCompare(b.label));
          const roomOptions = roomResponse.data
            .map(room => ({ value: room.name, label: room.name }))
            .sort((a, b) => a.label.localeCompare(b.label));

          setFilteredOptions({
            professors: professorOptions,
            rooms: roomOptions,
          });
        }
      } catch (err) {
        console.error('Error fetching options:', err.response ? err.response.data : err.message);
      } finally {
        setLoading(false);
      }
    }, 500),
    [options.groups.length]
  );

  useEffect(() => {
    fetchOptions(selectedGroup ? selectedGroup.value : null);
  }, [selectedGroup]);

  // Synchronize internal state with selectedFilters
  useEffect(() => {
    // Update selectedGroup
    if (selectedFilters.group && options.groups.length) {
      const groupOption = options.groups.find(group => group.value === selectedFilters.group);
      if (groupOption) {
        setSelectedGroup(groupOption);
      }
    } else if (!selectedFilters.group) {
      setSelectedGroup(null);
    }

    // Update selectedProfessor
    if (selectedFilters.professor && filteredOptions.professors.length) {
      const professorOption = filteredOptions.professors.find(prof => prof.value === selectedFilters.professor);
      if (professorOption) {
        setSelectedProfessor(professorOption);
      }
    } else if (!selectedFilters.professor) {
      setSelectedProfessor(null);
    }

    // Update selectedRoom
    if (selectedFilters.room && filteredOptions.rooms.length) {
      const roomOption = filteredOptions.rooms.find(room => room.value === selectedFilters.room);
      if (roomOption) {
        setSelectedRoom(roomOption);
      }
    } else if (!selectedFilters.room) {
      setSelectedRoom(null);
    }
  }, [selectedFilters, options.groups, filteredOptions.professors, filteredOptions.rooms]);

  const handleGroupChange = selectedOption => {
    const group = selectedOption ? selectedOption.value : '';
    setSelectedGroup(selectedOption);
    onSearch({ group, professor: '', room: '' });
  };

  const handleProfessorChange = selectedOption => {
    setSelectedProfessor(selectedOption);
    onSearch({
      group: selectedGroup ? selectedGroup.value : '',
      professor: selectedOption ? selectedOption.value : '',
      room: selectedRoom ? selectedRoom.value : '',
    });
  };

  const handleRoomChange = selectedOption => {
    setSelectedRoom(selectedOption);
    onSearch({
      group: selectedGroup ? selectedGroup.value : '',
      professor: selectedProfessor ? selectedProfessor.value : '',
      room: selectedOption ? selectedOption.value : '',
    });
  };

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: isMobile ? '260px' : '250px',
      padding: '5px',
      fontSize: '16px',
      borderRadius: '4px',
      border: state.isFocused ? '1px solid #212121' : '1px solid #181818',
      boxShadow: state.isFocused ? '0 0 0 1px #007bff' : null,
      backgroundColor: isDarkMode ? '#444' : '#fff',
      color: isDarkMode ? '#ffffff' : '#333',
    }),
    menu: provided => ({
      ...provided,
      zIndex: 1000,
      backgroundColor: isDarkMode ? '#444' : '#fff',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : isDarkMode ? '#444' : '#fff',
      color: state.isSelected ? '#fff' : isDarkMode ? '#ffffff' : '#333',
      cursor: 'pointer',
    }),
    singleValue: provided => ({
      ...provided,
      color: isDarkMode ? '#ffffff' : '#333',
    }),
    input: provided => ({
      ...provided,
      color: isDarkMode ? '#ffffff' : '#333',
    }),
  };

  return (
<form
  className="search-bar pl-4 pr-4"
  style={{ width: isAdmin ? '100%' : 'auto' }}
>
      {loading && <></>}
      <Select
        value={selectedGroup}
        onChange={handleGroupChange}
        options={options.groups}
        placeholder="Selectează Grupa"
        styles={customSelectStyles}
        isClearable
        aria-label="Selectează Grupa"
      />

      <Select
        value={selectedProfessor}
        onChange={handleProfessorChange}
        options={filteredOptions.professors}
        placeholder="Selectează Profesor"
        styles={customSelectStyles}
        isClearable
        aria-label="Selectează Profesor"
      />

      <Select
        value={selectedRoom}
        onChange={handleRoomChange}
        options={filteredOptions.rooms}
        placeholder="Selectează Sală"
        styles={customSelectStyles}
        isClearable
        aria-label="Selectează Sală"
      />
      {!isAdmin && (
        <>
          <div className="btn-row">
            <button type="button" className="print-button" aria-label="Share timetable" onClick={handleShareClick}>
              <span className="material-icons custom-icon">share</span>
            </button>
            <button type="button" onClick={onToggleTheme} className="theme-toggle-button" aria-label="Toggle theme">
              <span className="material-icons custom-icon">{isDarkMode ? 'dark_mode' : 'wb_sunny'}</span>
            </button>
          </div>

          <div className="week-type-display">
            <div className="week-type-row">
              <hr className="week-line even-week" />
              <span className={`week-text ${currentWeekType === 'even' ? 'bold' : ''}`}>Pară</span>
            </div>

            <div className="week-type-row">
              <hr className="week-line odd-week" />
              <span className={`week-text ${currentWeekType === 'odd' ? 'bold' : ''}`}>Impară</span>
            </div>
          </div>
        </>
      )}

      {/* Modal Component */}
      <Modal
  isOpen={isModalOpen}
  onRequestClose={handleCloseModal}
  className={`share-modal ${isClosing ? 'slide-up' : 'slide-down'}`}
  overlayClassName="modal-overlay"
  shouldCloseOnOverlayClick={true}
  ariaHideApp={false}
>
  <div className="modal-content-home">
    <p className="qr-title">Scanează QR code</p>
    <div className="qr-code-container">
      <QRCode
        value={generateShareLink()}
        logoImage="https://e-orar.ase.md/logo.png"
        size={220}
        qrStyle="squares"
        eyeRadius={5}
        logoWidth={50}
        logoHeight={50}
        logoOpacity={1}
        removeQrCodeBehindLogo={true}
        logoPaddingStyle={"circle"}
        bgColor={isDarkMode ? "#292929" : "#ffffff"}  // Dark background for dark mode
        fgColor={isDarkMode ? "#ffffff" : "#000000"}  // White foreground for dark mode
        style={{ borderRadius: '15px' }}
        />
    </div>
    <p className="subtitle">Distribuie acest cod QR prietenilor tăi pentru a accesa orarul.</p>
    <div className="divider">
      <hr />
      <span>sau copiază link-ul</span>
      <hr />
    </div>
    <div className={`input-container ${copySuccess ? 'success' : ''}`}>
      <input
        type="text"
        value={generateShareLink()}
        readOnly
        className={`share-input ${copySuccess ? 'copied' : ''}`}
        onClick={handleCopyLink}
      />
      {copySuccess && <p className="copy-message">Link copiat în clipboard!</p>}
    </div>
    <button
      type="button"
      className="close-button red"
      onClick={handleCloseModal}
    >
      Ascunde
    </button>
  </div>
</Modal>


    </form>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onToggleTheme: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool,
  selectedFilters: PropTypes.object.isRequired,
};

export default SearchBar;
