import { useRef, useEffect } from 'react';
import { gapi } from 'gapi-script';
import { DateTime } from 'luxon';

const CLIENT_ID = '111888019672-cujgj21jd7r3717c6jkjl7f9vkhvdtq6.apps.googleusercontent.com';
const API_KEY = 'AIzaSyCA61w5mKko8oDiCjEKAmrcSLnpZteCUNs';
const SCOPES = 'https://www.googleapis.com/auth/calendar.events';

const useGoogleCalendar = () => {
  const isInitialized = useRef(false);

  useEffect(() => {
    const initializeGapi = () => {
      if (isInitialized.current) return; // Prevent multiple initializations
      gapi.load('client:auth2', () => {
        gapi.client
          .init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
            scope: SCOPES,
          })
          .then(() => {
            isInitialized.current = true;
            console.log('Google API initialized successfully');
          })
          .catch((error) => {
            console.error('Error initializing Google API:', error.message);
          });
      });
    };
    initializeGapi();
  }, []);

  const signIn = async () => {
    try {
      await gapi.auth2.getAuthInstance().signIn();
      console.log('Signed in successfully');
    } catch (error) {
      console.error('Error signing in:', error);
    }
  };

  const signOut = () => {
    try {
      const authInstance = gapi.auth2.getAuthInstance();
      if (authInstance.isSignedIn.get()) {
        authInstance.signOut();
        console.log('Signed out successfully');
      }
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const addEventToGoogleCalendar = async (eventData) => {
    try {
      const authInstance = gapi.auth2.getAuthInstance();
  
      // Check if the user is signed in
      if (!authInstance.isSignedIn.get()) {
        console.log('User is not authenticated, prompting sign-in...');
        await authInstance.signIn(); // Prompt the user to log in
      }
  
      const { date, time } = eventData;
      const [startTime, endTime] = time.split(' - ');
  
      // Parse and adjust time using Luxon
      const toISOWithTimeZone = (date, time) => {
        return DateTime.fromFormat(`${date} ${time}`, 'yyyy-MM-dd HH:mm', {
          zone: 'Europe/Bucharest',
        }).toISO();
      };
  
      const startDateTime = toISOWithTimeZone(date, startTime);
      const endDateTime = toISOWithTimeZone(date, endTime);
  
      // Construct the Google Calendar event
      const event = {
        summary: eventData.subject || 'Untitled Event',
        location: eventData.room || 'No Location',
        description: `Professor: ${eventData.professor || 'N/A'}`,
        start: {
          dateTime: startDateTime,
          timeZone: 'Europe/Bucharest',
        },
        end: {
          dateTime: endDateTime,
          timeZone: 'Europe/Bucharest',
        },
        reminders: {
          useDefault: false,
          overrides: [
            { method: 'email', minutes: 10 },  // Email reminder 10 minutes before
            { method: 'popup', minutes: 10 },  // Popup reminder 10 minutes before
          ],
        },
      };
  
      // Add event to Google Calendar
      const response = await gapi.client.calendar.events.insert({
        calendarId: 'primary',
        resource: event,
      });
      console.log('Event created: ', response);
    } catch (error) {
      console.error('Error adding event to Google Calendar:', error.message);
    }
  };
  
  return { signIn, signOut, addEventToGoogleCalendar };
};

export default useGoogleCalendar;
